import { DependencyList, Dispatch, SetStateAction, useEffect, useState } from 'react'

import { EventEmitter2 as EventEmitter } from 'eventemitter2'
import { v4 as uuid } from 'uuid'

import { STEP } from '../controller'
import { DEMO_STAGE } from '../demo/index.demo'
import { IndexTuple } from '../lib/file.parser'
import { IRule } from '../lib/recipe'
import { IResultRecord } from '../lib/view'
import { ITheme } from '../theme'
import {
  IBeforeFetchRequest,
  IBeforeFetchResponse,
  IDataHookResponse,
  IHookAtom,
  ILegacyMeta,
  IStepMeta,
  Scalar,
  ScalarDictionary,
  ScalarDictionaryWithCustom
} from '../types/general.interface'
import {
  IBatchConfig,
  IFieldBase,
  ISettings,
  IVirtualFieldOptions
} from '../types/settings.interface'

export interface IEvents {
  'record:change': { data: ScalarDictionaryWithCustom; sequence: number }
  'record:init': { data: ScalarDictionaryWithCustom; sequence: number }
  'record:init:batch': { data: [ScalarDictionaryWithCustom, number][] }

  'virtual:record:init:batch': { data: [ScalarDictionaryWithCustom, number][] }

  'view:sample': { data: ScalarDictionary[]; sequence: number }
  'view:init': { data: ScalarDictionary[]; sequence: number }

  'field:init': { data: IndexTuple<Scalar>[]; meta: { field: string } }

  'step:change': { step: STEP; payload: IStepMeta | IRule }

  'data:submit': { results: IResultRecord[]; meta: ILegacyMeta }
  'error:network': string
  'fetch:before': IBeforeFetchRequest
  'recipe:change': undefined
  settings: ISettings
  ready: boolean
  open: boolean

  'do/record:change': IDataHookResponse
  'do/record:create': IDataHookResponse
  'do/recipe:change': IDataHookResponse

  'do/close': undefined
  exception: ExceptionEvent

  'do/open': IBatchConfig
  'do/corrections': IDataHookResponse[]
  'do/chunk:next': undefined
  'do/dialog:display': IDialogConfig
  'do/setLanguage': { lang: string }
  'do/addVirtualField': { field: IFieldBase; options: IVirtualFieldOptions }
  'do/demo': { theme?: ITheme; stage?: DEMO_STAGE }
}

interface IEventResponses {
  settings: ISettings
  'record:change': IDataHookResponse
  'record:init': IDataHookResponse
  'record:init:batch': IDataHookResponse[]
  'virtual:record:init:batch': IDataHookResponse[]
  'field:init': IndexTuple<IHookAtom>[]
  'fetch:before': IBeforeFetchResponse
  'step:change': boolean
}

interface IDialogConfig {
  message?: string
  type?: 'progress' | 'error' | 'success'
}

type Event<T> = {
  ref?: number
  wait: boolean
  sequence: ISequence
  t: number
  err?: string
  payload: T
}

// tslint:disable:no-any
type ExceptionEvent = {
  original: Event<any>
  t: number
  error: string
}

let openPromises: [(value: any) => any, (err: any) => any][] = []

export const eventManager = new EventEmitter()
function registerReplyListener() {
  eventManager.on('reply', (event: Event<any>) => {
    if (event.ref !== undefined && openPromises[event.ref]) {
      const [resolve, reject] = openPromises[event.ref]
      if (event.err) {
        reject(event.err)
      } else {
        resolve(event.payload)
      }
      delete openPromises[event.ref]
    } else {
      const exception: ExceptionEvent = {
        original: event,
        t: performance.now(),
        error: `Only one reply can be received per event, 2nd callback for ${event.ref} ignored`
      }
      eventManager.emit('exception', exception)
    }
  })
}
registerReplyListener()
// tslint:enable:no-any

/**
 * Use an event listener inside a functional react component
 *
 * @param event
 * @param cb
 * @param deps
 */
export function useListener<K extends keyof IEvents>(
  event: K,
  cb: (e: Event<IEvents[K]>) => void,
  deps: DependencyList = []
) {
  useEffect(() => {
    return listen(event, cb)
  }, deps)
}

/**
 * Use the state as returned from an event listener
 *
 * @param event
 * @param cb
 * @param deps
 */
export function useRequest<K extends keyof Pick<IEvents, keyof IEventResponses>>(
  event: K,
  cb: (e: IEventResponses[K]) => void,
  deps: DependencyList = []
) {
  useEffect(() => {
    emitAndWait(event, undefined as unknown as IEvents[K]).then((value) => {
      cb(value)
    })
  }, deps)
}

/**
 * Use the state as returned from an event listener
 *
 * @param eventName
 * @param def
 * @param deps
 */
export function useEmittedState<K extends keyof IEvents, S extends IEvents[K]>(
  eventName: K,
  def?: S,
  deps: DependencyList = []
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState<S>(def as S)
  useEffect(() => {
    return listen(eventName, (event) => {
      setState(event.payload as S)
    })
  }, deps)
  return [state, setState]
}

let eventCounter = 0

/**
 * Emit an event and return a promise that will resolve once a reply event is received
 *
 * @param event
 * @param payload
 * @param sequence
 */
export function emitAndWait<K extends keyof Pick<IEvents, keyof IEventResponses>>(
  event: K,
  payload: IEvents[K],
  sequence?: ISequence
): Promise<IEventResponses[K]> {
  const e: Event<IEvents[K]> = {
    ref: eventCounter++,
    payload,
    sequence: ensureSequence(sequence),
    wait: true,
    t: performance.now()
  }

  return new Promise((resolve, reject) => {
    openPromises[e.ref as number] = [resolve, reject]
    emitOrQueue(event, e)
  })
}
type FilterFlags<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}
type AllowedNames<Base, Condition> = FilterFlags<Base, Condition>[keyof Base]
type Diff<T, U> = T extends U ? never : T

/**
 * Emit an event without a callback expectation
 *
 * @param event
 * @param sequence
 */
export function emit<K extends AllowedNames<IEvents, undefined>>(
  event: K,
  sequence?: ISequence
): void
export function emit<
  K extends Diff<keyof IEvents, AllowedNames<IEvents, undefined>>,
  Attr extends IEvents[K]
>(event: K, payload: Attr, sequence?: ISequence): void
export function emit<K extends keyof IEvents, Attr extends IEvents[K]>(
  event: K,
  payload?: Attr,
  sequence?: ISequence
): void {
  const e: Event<IEvents[K]> = {
    ref: eventCounter++,
    payload: payload as IEvents[K],
    sequence: ensureSequence(sequence),
    wait: false,
    t: performance.now()
  }
  eventManager.emit(event, e)
}

let eventQueue: {
  name: keyof IEvents
  // tslint:disable-next-line:no-any
  event: Event<any>
}[] = []
/**
 * Emit an event without a callback expectation but ensure it is picked up by the next listener
 *
 * @param event
 * @param sequence
 */
export function enqueue<K extends AllowedNames<IEvents, undefined>>(
  event: K,
  sequence?: ISequence
): void
export function enqueue<
  K extends Diff<keyof IEvents, AllowedNames<IEvents, undefined>>,
  Attr extends IEvents[K]
>(event: K, payload: Attr, sequence?: ISequence): void
export function enqueue<K extends keyof IEvents, Attr extends IEvents[K]>(
  event: K,
  payload?: Attr,
  sequence?: ISequence
): void {
  const e: Event<IEvents[K]> = {
    ref: eventCounter++,
    sequence: ensureSequence(sequence),
    payload: payload as IEvents[K],
    wait: false,
    t: performance.now()
  }
  emitOrQueue(event, e)
}

/**
 * Listen for an event, returns a cancel function
 *
 * @param event
 * @param cb
 */
export function listen<K extends keyof IEvents>(
  event: K,
  cb: (e: Event<IEvents[K]>) => void
): () => void {
  eventManager.on(event, cb)
  drainQueue(event, cb)
  return () => {
    eventManager.off(event, cb)
  }
}

/**
 * Listen for an event and return a promise reply, returns a cancel function
 *
 * @param event
 * @param cb
 */
export function listenAndReply<K extends keyof Pick<IEvents, keyof IEventResponses>>(
  event: K,
  cb: (e: Event<IEvents[K]>) => Promise<IEventResponses[K]>
): () => void {
  const wrappedCallback = async (e: Event<IEvents[K]>) => {
    try {
      const payload = await cb(e)
      const replyEvent: Event<IEventResponses[K]> = {
        ref: e.ref,
        payload,
        sequence: ensureSequence(),
        wait: false,
        t: performance.now()
      }
      eventManager.emit('reply', replyEvent)
    } catch (err) {
      const errEvent: Event<IEventResponses[K]> = {
        ref: e.ref,
        wait: false,
        sequence: ensureSequence(),
        payload: undefined as unknown as IEventResponses[K],
        err,
        t: performance.now()
      }
      eventManager.emit('reply', errEvent)
    }
  }
  eventManager.on(event, wrappedCallback)
  drainQueue(event, wrappedCallback)

  return () => {
    eventManager.off(event, wrappedCallback)
  }
}

export function removeAllListeners<K extends keyof IEvents>(event: K) {
  eventManager.removeAllListeners(event)
}

export function cleanup() {
  eventManager.removeAllListeners()
  registerReplyListener()
  openPromises = []
  eventQueue = []
}

export function hasListeners<K extends keyof IEvents>(event: K) {
  return !!eventManager.listeners(event).length
}

interface ISequence {
  id: string
  index: number
  length: number
}

function ensureSequence(sequence?: ISequence): ISequence {
  return sequence || { id: uuid(), index: 0, length: 1 }
}

/**
 * Drain a queue to a callback handler
 *
 * @param event
 * @param cb
 */
function drainQueue<K extends keyof IEvents>(event: K, cb: (e: Event<IEvents[K]>) => void) {
  const queued = eventQueue.filter((e) => e.name === event)
  if (queued.length) {
    queued.forEach(({ event: e }) => cb(e))
  }
  eventQueue = eventQueue.filter((e) => e.name !== event)
}

/**
 * Emit an event immediately or enqueue and wait for a listener
 *
 * @param event
 * @param e
 */
function emitOrQueue<K extends keyof IEvents>(event: K, e: Event<IEvents[K]>) {
  if (!hasListeners(event)) {
    eventQueue.push({ name: event, event: e })
  } else {
    eventManager.emit(event, e)
  }
}
