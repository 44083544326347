import { LogLevelDesc } from 'loglevel'

export const HOT_LICENSE_KEY = process.env.HOT_LICENSE_KEY || 'no-license'

export const ROOT_API_URL = process.env.ROOT_API_URL || null

export const SENTRY_CONFIG: {
  dsn?: string
  release?: string
  enabled: boolean
  debug: boolean
  environment: string
} = {
  dsn: process.env.SENTRY_DSN,
  release: process.env.SENTRY_RELEASE,
  enabled: process.env.SENTRY_ENABLED === 'true',
  debug: process.env.SENTRY_DEBUG === 'true',
  environment: process.env.SENTRY_ENVIRONMENT || 'local'
}

/**
 * Maximum amount of time to hold the progress bar before completing
 * This is to prevent broken loops from leaving the progress bar running
 */
export const ITERATOR_MAX_SILENCE = parseInt(process.env.ITERATOR_MAX_SILENCE || '3000', 10)

/**
 * Maximum amount of time to allow the event loop to run without a progress bar
 */
export const ITERATOR_MAX_BLOCK_TIME = parseInt(process.env.ITERATOR_MAX_BLOCK_TIME || '500', 10)

/**
 * Enforced FPS for iterator
 */
export const ITERATOR_FPS = parseInt(process.env.ITERATOR_FPS || '60', 10)

export const DEPLOY_URL =
  process.env.CONTEXT === 'production'
    ? process.env.URL ?? ''
    : process.env.KIOSKLITE_DEPLOY_URL ?? process.env.DEPLOY_URL ?? ''

export const RELATIVE_DEPLOY_URL = DEPLOY_URL.replace(/https?:\/\/[^\/]+/, '')

export const INTERACTION_EVENT_THROTTLE_TIME = 5000

export const LOG_LEVEL = (process.env.LOG_LEVEL || 'warn') as LogLevelDesc

export const MIN_ROWS = 20

export const BASE_COL_SIZE = 160

export const DETECT_ENCODING_LIMIT = 250

export const MAX_SELECT_OPTIONS = 100

export const HEADER_CHECK_LIMIT = 10

export const RECORD_HOOK_BATCH_ROWS = parseInt(process.env.RECORD_HOOK_BATCH_ROWS ?? '100', 10)

// all translated languages
export const LANGUAGE_SUPPORTED = [
  'en',
  'de',
  'de-DE',
  'en-CA',
  'en-GB',
  'en-US',
  'es',
  'es-ES',
  'fr',
  'fr-FR',
  'it',
  'ja',
  'ko',
  'nl',
  'pl',
  'pt-BR',
  'sv',
  'th',
  'tr',
  'zh-Hans',
  'zh-Hant',
  'zh-Hant-TW'
]

export const LANGUAGE_WHITELIST = LANGUAGE_SUPPORTED.concat(['de-AT', 'de-CH', 'et', 'fi'])
