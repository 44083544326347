import { error } from 'loglevel'
import qs from 'query-string'

/**
 * Convert a number into an XLS style column letter
 * eg. 27 => AA
 *
 * @param number
 */
export function convertToLetters(number: number): string {
  const baseChar = 'A'.charCodeAt(0)
  let letters = ''
  do {
    number -= 1
    letters = String.fromCharCode(baseChar + (number % 26)) + letters
    // tslint:disable-next-line:no-bitwise
    number = (number / 26) >> 0
  } while (number > 0)
  return letters
}

/**
 * Join an array of words with natural language
 *
 * @param fields
 * @param any
 * @param junctions
 */
export function naturalJoin(
  fields: string[],
  any: boolean = false,
  junctions: [string, string] = ['is', 'are']
): string {
  const [s, p] = junctions
  const joinTerm = any ? 'or' : 'and'
  if (fields.length === 1) {
    return `${fields[0]} ${s}`
  } else if (fields.length === 2) {
    return `${fields.join(` ${joinTerm} `)} ${p}`
  } else if (fields.length > 2) {
    const last = fields.pop()
    return `${fields.join(', ')} ${joinTerm} ${last} ${p}`
  }
  return ''
}

/**
 * Ready safely from the browser cache
 * @param ns
 * @param key
 */
export function readCache<T>(ns: string, key: string): T | undefined {
  try {
    const value = window.localStorage.getItem(`${ns}:${key}`)
    if (!value) {
      return undefined
    }
    return JSON.parse(value) as T
  } catch (e) {
    error(e)
    return undefined
  }
}

/**
 * Write safely to the browser cache
 * @param ns
 * @param key
 * @param value
 */
export function writeCache<T>(ns: string, key: string, value: T): void {
  try {
    window.localStorage.setItem(`${ns}:${key}`, JSON.stringify(value))
  } catch (e) {
    error(e)
  }
}

/**
 * Clear the cache
 * @param ns
 * @param key
 */
export function clearCache(ns: string, key: string): void {
  try {
    window.localStorage.removeItem(`${ns}:${key}`)
  } catch (e) {
    error(e)
  }
}

const insertGroupingRegexp = /(\d+)(\d{3})/

function insertGrouping(s: string, grouping: string) {
  const times = Math.floor(s.length / 3)
  for (let i = 0; i < times; i++) {
    s = s.replace(insertGroupingRegexp, '$1' + grouping + '$2')
  }
  return s
}

export function formatNumber(
  n: number,
  options?: {
    fractionDigits?: number
    useGrouping?: boolean
    symbols?: {
      decimal: string
      grouping: string
    }
  }
) {
  // Set default options
  options = options || {}
  options.fractionDigits =
    typeof options.fractionDigits !== 'undefined' ? options.fractionDigits : 2
  options.useGrouping = typeof options.useGrouping !== 'undefined' ? options.useGrouping : true
  options.symbols =
    typeof options.symbols !== 'undefined'
      ? options.symbols
      : {
          // US formats
          grouping: ',',
          decimal: '.'
        }

  const parts = n.toFixed(options.fractionDigits).split('.')
  let integerPart = parts[0]
  const decimalPart = parts[1]
  if (options.useGrouping) {
    integerPart = insertGrouping(integerPart, options.symbols.grouping)
  }
  return integerPart + (options.fractionDigits > 0 ? options.symbols.decimal + decimalPart : '')
}

export const detach = <T>(promiseOrAsyncFn: (() => Promise<T>) | Promise<T>) => {
  const promise = typeof promiseOrAsyncFn === 'function' ? promiseOrAsyncFn() : promiseOrAsyncFn
  promise.then(
    () => void 0,
    (e) => {
      error(e)
    }
  )
}

/**
 * Return the first non undefined attribute
 * @param args
 */
// tslint:disable-next-line:no-any
export const coalesce = <T extends any>(...args: T[]): undefined | T => {
  for (const arg of args) {
    if (arg !== undefined) {
      return arg
    }
  }
  return undefined
}

/**
 * Return a object container headers to be provided in a object storage request
 */
export const getSignedUrlHeaders = (signedUrl: string) => {
  // https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObject.html
  const allowedHeaders = [
    'x-amz-acl',
    'Cache-Control',
    'Content-Disposition',
    'Content-Encoding',
    'Content-Language',
    'Content-Length',
    'Content-MD5',
    'Content-Type',
    'Expires',
    'x-amz-grant-full-control',
    'x-amz-grant-read',
    'x-amz-grant-read-acp',
    'x-amz-grant-write-acp',
    'x-amz-server-side-encryption',
    'x-amz-storage-class',
    'x-amz-website-redirect-location',
    'x-amz-server-side-encryption-customer-algorithm',
    'x-amz-server-side-encryption-customer-key',
    'x-amz-server-side-encryption-customer-key-MD5',
    'x-amz-server-side-encryption-aws-kms-key-id',
    'x-amz-server-side-encryption-context',
    'x-amz-request-payer',
    'x-amz-tagging',
    'x-amz-object-lock-mode',
    'x-amz-object-lock-retain-until-date',
    'x-amz-object-lock-legal-hold',
    'x-amz-expected-bucket-owner'
  ]
  return Object.entries(qs.parseUrl(signedUrl).query).reduce((acc, [key, value]) => {
    if (allowedHeaders.indexOf(key) > -1 || key.indexOf('x-amz-meta-') > -1) {
      acc[key] = (value ?? '').toString()
    }
    return acc
  }, {} as Record<string, string>)
}
