import React, {
  FunctionComponent,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { HotTable } from '@handsontable/react'
import Handsontable from 'handsontable'
import times from 'lodash/times'
import { debug } from 'loglevel'
import { useTranslation } from 'react-i18next'
import sanitizeHtml from 'sanitize-html-react'
import styled from 'styled-components'
import tippy, { Instance } from 'tippy.js'

import { BASE_COL_SIZE, HOT_LICENSE_KEY, MIN_ROWS } from '../config'
import { SettingsContext } from '../context/settings.context'
import { InfoIconString, RequiredIconString } from '../fragments/icons'
import {
  FormatBoldIcon,
  FormatClearIcon,
  FormatItalicIcon,
  FormatStrikeIcon
} from '../fragments/icons'
import { IRule } from '../lib/recipe'
import { View, VIEW_FILTER } from '../lib/view'
import { ICellStyles } from '../types/general.interface'
import { IField, IFieldOptionDictionary } from '../types/settings.interface'
import { makeCustomRenderer } from '../utils/hot.renderer'
import { isEmpty } from '../utils/misc'
import { isFieldRequired } from '../utils/settings.helpers'

import PredefinedMenuItemKey = Handsontable.contextMenu.PredefinedMenuItemKey

const StyledFormats = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  margin-top: -15px;
  margin-bottom: 10px;

  button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    margin-right: 0.5rem;
    border-radius: 0.33rem;
    transition: 0.3s;
    outline: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const DataTable: FunctionComponent<{
  view: View
  viewPort: RefObject<HTMLDivElement>
  filters?: VIEW_FILTER[]
  uploaded?: boolean
  onFirstInput?: () => void
  forceHeight?: number
  isManualUpload?: boolean
  onValidate?: (valid: boolean) => void
}> = ({
  view,
  viewPort,
  filters,
  uploaded,
  onFirstInput,
  forceHeight,
  isManualUpload,
  onValidate
}) => {
  const hotTableRef = React.createRef() as RefObject<HotTable>
  const tippiesRef = React.useRef<{ [key: string]: Instance }>({})
  const selectionRef = React.useRef<[number, number, number, number]>()
  const [render, allowRender] = useState(false)
  const [hasInput, setHasInput] = useState<boolean>(false)
  const [hiddenColumns, setHiddenColumns] = useState<number[]>([])
  const [tableHeight, $setTableHeight] = useState(forceHeight ?? 23 * MIN_ROWS + 4 + 25)
  const settings = useContext(SettingsContext)

  const contextMenu = uploaded
    ? ['remove_row']
    : ['row_above', 'row_below', 'remove_row', 'undo', 'redo']

  let matrix: [IRule, IField][]

  matrix = view.getColumnSettings()
  view.applyFilters(filters || [])
  const data = view.getEmptyDataGrid(filters)

  useEffect(() => {
    const hideFields = view.recipe
      .getVirtualRules()
      .reduce((acc, rule) => [...acc, ...(rule?.hideFields || [])], [] as string[])
      .filter((targetKey, index, self) => targetKey && self.indexOf(targetKey) === index)

    const hideColumns = view.recipe
      .getWorkingRules()
      .map((rule, index) => [rule, index] as [IRule, number])
      .filter(([rule]) => !rule.virtual && rule.targetKey && hideFields.includes(rule.targetKey))
      .map(([, index]) => index + 1)

    if (hideColumns.length) {
      setHiddenColumns(hideColumns)
    }
  }, [])

  useEffect(() => {
    if (!render) {
      Handsontable.renderers.registerRenderer('ff.custom', makeCustomRenderer(view))
      allowRender(true)
    }
  }, [view])

  useEffect(() => {
    // Expose the raw hot for testing
    if (process.env.NODE_ENV === 'test') {
      // @ts-ignore
      window.testingTable = hotTableRef
    }
  }, [hotTableRef])

  const setTableHeight = (rowCount: number) => $setTableHeight(23 * Math.min(rowCount, 40) + 4 + 25)

  const columnWidths = calculateColumnWidths(view, viewPort.current, hiddenColumns)

  const { t } = useTranslation()

  const handleChangeFormat = useCallback<(format: keyof ICellStyles | 'clear') => void>(
    (format) => {
      const hot = hotTableRef.current

      if (hot && selectionRef.current?.length === 4) {
        const [row, col, row2, col2] = selectionRef.current
        const value =
          format === 'clear'
            ? false
            : !(
                view?.getStylesAtPoint(hot.hotInstance.getDataAtCell(row, 0), col - 1)[format] ??
                false
              )

        const rows = [row, row2].sort()
        const cols = [col, col2].sort()

        for (let r = rows[0]; r <= rows[1]; r++) {
          for (let c = cols[0]; c <= cols[1]; c++) {
            view?.overrideStylesAtPoint(hot.hotInstance.getDataAtCell(r, 0), c - 1, format, value)
          }
        }

        hot.hotInstance.render()
      }
    },
    [view, hotTableRef, selectionRef]
  )

  return !render || !viewPort.current ? (
    <div>Loading...</div>
  ) : !data.length ? (
    <div className='emptyState'>{t('noData')}</div>
  ) : (
    <div className='handsontable-container'>
      {uploaded && settings?.allowFormatting && (
        <StyledFormats className='secondaryTextColor'>
          <button id='btn--format-bold' onClick={() => handleChangeFormat('bold')}>
            <FormatBoldIcon />
          </button>
          <button id='btn--format-italic' onClick={() => handleChangeFormat('italic')}>
            <FormatItalicIcon />
          </button>
          <button id='btn--format-strike' onClick={() => handleChangeFormat('strike')}>
            <FormatStrikeIcon />
          </button>
          <button id='btn--format-clear' onClick={() => handleChangeFormat('clear')}>
            <FormatClearIcon />
          </button>
        </StyledFormats>
      )}
      <HotTable
        id='hot'
        ref={hotTableRef}
        settings={{
          licenseKey: HOT_LICENSE_KEY,
          data,
          width: '100%',
          columnHeaderHeight: 23,
          hiddenColumns: { columns: [0, ...hiddenColumns], indicators: false },
          colHeaders: (index) => {
            if (index === 0) {
              return '$index'
            }
            if (index - 1 < matrix.length) {
              const field = matrix[index - 1][1]
              return `<span class="tooltip-icon">${
                isFieldRequired(field) ? RequiredIconString : ``
              } ${field.description ? InfoIconString : ''}</span> ${sanitizeHtml(field.label)}`
            }
            return 'x'
          },
          afterSelection: (row, col, row2, col2) => {
            selectionRef.current = [row, col, row2, col2]
          },
          afterDeselect: () => {
            selectionRef.current = undefined
          },
          outsideClickDeselects: (target) => {
            return !target.closest(`[id^='btn--format-']`)
          },
          afterGetColHeader: (index, el) => {
            const colIndex = el.getAttribute('data-tippy')
            if (colIndex && tippiesRef.current[colIndex]) {
              tippiesRef.current[colIndex].destroy()
            }

            if (
              index > 0 &&
              index <= matrix.length &&
              matrix[index - 1][1].description !== undefined
            ) {
              if (!colIndex) {
                el.setAttribute('data-tippy', index.toString())
              }

              tippiesRef.current[colIndex || index.toString()] = tippy(el, {
                content: `<span class="tippy-content-override">${sanitizeHtml(
                  matrix[index - 1][1].description
                )}</span>`,
                placement: `top-end`,
                distance: -3
              }) as Instance
            }
          },
          colWidths: [0, ...columnWidths],
          columns: (index) => {
            if (index === 0) {
              return {
                data: index,
                readOnly: true
              }
            }
            if (matrix.length && index <= matrix.length) {
              const [, col] = matrix[index - 1]
              // tslint:disable-next-line:no-any
              const accessor = view.getValueAccessor(index - 1, col) as any
              const res = {
                data: accessor,
                allowInvalid: true,
                renderer: 'ff.custom',
                readOnly: false
              }
              if (col.type === 'checkbox') {
                return {
                  ...res
                  // type: 'checkbox'
                }
              } else if (col.type === 'select') {
                const options = col.options.map((o: IFieldOptionDictionary) => o.label)

                return {
                  ...res,
                  type: 'dropdown',
                  source: options
                }
              } else {
                return res
              }
            }
            return {}
          },
          beforePaste(pasteData, coords) {
            if (hotTableRef && hotTableRef.current) {
              pasteData?.forEach((row, i) => {
                row?.forEach((cell, j) => {
                  if (cell?.length) {
                    pasteData[i][j] = cell?.trim()
                  }
                })
              })

              const hot = hotTableRef.current.hotInstance
              const pasteLocation = coords[0].endRow
              const currentRowCount = hot.countRows()
              const newDataLength = pasteData.length
              if (newDataLength + pasteLocation > currentRowCount) {
                const rowsToAdd = newDataLength + pasteLocation - currentRowCount
                hot.alter('insert_row', currentRowCount, rowsToAdd)
              }
            }
          },
          async afterChange(changes, source) {
            const hot = this as unknown as Handsontable
            const rowSelected = hot?.getSelected()?.[0][0]
            const currentNumberOfRows = hot?.countRows() - 1
            // if we edit a row and it is the last one, insert 1 row below
            const isLastRow = rowSelected === currentNumberOfRows
            if (source === 'edit' && isLastRow && isManualUpload) {
              hot.alter('insert_row', hot?.countRows(), 1)
            }
            if (changes) {
              if (!hasInput && onFirstInput) {
                const [selected] = hot.getSelected() || []
                onFirstInput()
                setTimeout(() => {
                  setHasInput(true)
                  if (selected?.length >= 2) {
                    hot.deselectCell()
                    hot.selectCell(...selected)
                  }
                })
              }

              const affectedRows = changes
                .reduce((acc, [row]) => {
                  if (!acc.includes(row)) {
                    return [...acc, row]
                  }
                  return acc
                }, [] as number[])
                .map((rowIndex) => hot.getDataAtCell(rowIndex, 0))

              onValidate?.(false)
              for (const srcRow of affectedRows) {
                const res = await view.validator.validateRow(view.getRecord(srcRow))
                if (view.validator.rowsPending) {
                  const p = view.validator.rowsPending
                  view.validator.rowsPending = []
                  for (const i of p) {
                    const res2 = await view.validator.validateRow(view.getRecord(i))
                    debug('VALIDATING PENDING ROW', i, res2)
                  }
                }
                debug('VALIDATING ROW', srcRow, view.getRecord(srcRow), res)
              }
              onValidate?.(true)
              // cause a render
              hot.render()
            }
          },
          afterGetRowHeader(rowIndex, th) {
            const hot = this as unknown as Handsontable
            const values = hot.getDataAtRow(rowIndex)
            if (values.slice(1).every((v) => isEmpty(v))) {
              th.classList.add('is-empty-row')
            } else {
              th.classList.remove('is-empty-row')
            }
          },
          afterCreateRow(index, amount, source) {
            debug('ROW CREATED', index, amount, source)
            const hot = this as unknown as Handsontable
            const rowCount = hot.countRows()
            data[index][0] = 25
            const newIndexes = view.allocateNewRowIndexes(amount)
            times(amount, (i) => {
              data[index + i][0] = newIndexes[i]
            })
            setTimeout(() => setTableHeight(rowCount))
          },
          afterRemoveRow() {
            const hot = this as unknown as Handsontable
            const rowCount = hot.countRows()
            setTimeout(() => setTableHeight(rowCount))
          },
          modifyColWidth: (width, col) =>
            width < 40 && ![0, ...hiddenColumns].includes(col) ? 40 : width,
          height: tableHeight,
          copyPaste: true,
          manualColumnResize: true,
          manualRowResize: true,
          allowRemoveRow: false,
          viewportRowRenderingOffset: 25,
          viewportColumnRenderingOffset: 25,
          // observeChanges: true,
          rowHeaders(index) {
            if (!view.strictIndexes) {
              return (index + 1).toString()
            }
            const srcIndex = data[index][0] || 0
            return (srcIndex + 1).toString(10) || '?'
          },
          undo: true,
          wordWrap: false,
          // minSpareRows: 1,
          stretchH: 'last',
          contextMenu: contextMenu as PredefinedMenuItemKey[],
          renderAllRows: false,
          trimWhitespace: !settings?.preventAutoTrimming
        }}
      />
    </div>
  )
}

DataTable.displayName = 'DataTable'

export default DataTable

function calculateColumnWidths(
  view: View,
  el: HTMLDivElement | null,
  hiddenColumns: number[] = []
): number[] {
  const cols = view.getColumnSettings().filter((_, index) => !hiddenColumns.includes(index + 1))

  const initialColSize = el
    ? Math.max(BASE_COL_SIZE, Math.floor((el.clientWidth - 50) / cols.length))
    : BASE_COL_SIZE

  const virtualSize = cols.reduce((totalSize, [, column]) => totalSize + (column.sizeHint || 1), 0)

  const scaleFactor = cols.length / virtualSize // i.e. inverse of average sizeHint

  return view
    .getColumnSettings()
    .map(([, column]) =>
      Math.round(Math.max(scaleFactor * initialColSize, 120) * (column.sizeHint || 1))
    )
}
