import times from 'lodash/times'
import { debug } from 'loglevel'
import { default as md5 } from 'md5'

import {
  ICellStyles,
  IDictionary,
  IPrimitive,
  Nullable,
  ScalarDictionaryWithCustom
} from '../types/general.interface'
import { IBatchConfig, IField, IInputObject } from '../types/settings.interface'
import { coalesce, convertToLetters } from '../utils/functions'
import { asyncMap } from '../utils/iterators'
import { falsyRegex, truthyRegex } from '../utils/truthy.regex'
import { FileParser, IndexTuple, RowTuple, Tuple } from './file.parser'
import { IRule, Recipe } from './recipe'
import { IValidatedCell, recordIsEmpty, Validator } from './validator'

export class View {
  /**
   * Returns normalized data regardless of source
   * @readonly
   */
  public get data(): RowTuple[] {
    return this.parser ? this.parser.data : this.localData
  }

  /**
   * Returns data minus headers + no extra rows
   * @readonly
   * @todo optimize this somehow - would be better not to always be slicing
   */
  public get trimmedData(): RowTuple[] {
    const data = this.parser ? this.parser.data.slice(this.parser.startIndex) : this.localData
    // this removes rows that previous had data but now are empty
    // because an original column with data was ignored
    const removeEmptyRows = data
      .map((row) => {
        return this.mapRowToRecord(row, false)
      })
      .filter((row) => !recordIsEmpty(row))
      .map((item) => item[1])

    return data.filter((row) => removeEmptyRows.includes(row[1]))
  }

  public strictIndexes: boolean = true
  public filters: VIEW_FILTER[] = []
  public styles: IDictionary<ICellStyles> = {}

  private ignored: number[] = []
  private userEdits: Nullable<IPrimitive>[][] = []
  private gridCache?: [string, RowPlaceholder[]]
  private readonly parser?: FileParser
  private readonly localData: RowTuple[] = []

  constructor(src: FileParser | RowTuple[], public validator: Validator, public recipe: Recipe) {
    if (src instanceof FileParser) {
      this.parser = src
    } else {
      this.localData = src
    }

    debug('NEW VIEW', this.parser, this.localData)
  }

  /**
   * Allocates a number of new indexes based on the number of rows added
   * @param rowCount
   */
  public allocateNewRowIndexes(rowCount: number) {
    const lastActualIndex = this.data.length - 1
    return times(rowCount, (i) => {
      const newCellValues: string[] = Array(this.data[0][0].length).fill('')
      // Expand data to match the rows we're adding
      this.data[this.data.length] = [newCellValues, this.data.length]
      return lastActualIndex + ++i
    })
  }

  /**
   * Returns columns that need to be rendered along with their configuration
   * @todo support custom columns here where Field config may not be present
   */
  public getColumnSettings(): [IRule, IField][] {
    const rules = this.recipe.getWorkingRules()
    return rules.map((r) => [r, this.recipe.getFieldSettings(r.sourceIndex) as IField])
  }

  /**
   * Get the current validation state of a cell based off the view column
   *
   * @param srcRow
   * @param viewCol
   */
  public getValidationState(srcRow: number, viewCol: number): IValidatedCell {
    const { sourceIndex } = this.recipe.getWorkingRuleAt(viewCol)
    const value = this.getValueAtPoint(srcRow, viewCol)
    const [res] = this.validator.getRowValidation([[[value, sourceIndex]], srcRow])
    return res
  }

  /**
   * Generates an empty data object just for HoT, we don't use the raw object
   * because we don't want HoT mutating anything in it
   */
  public getEmptyDataGrid(filters?: VIEW_FILTER[]): RowPlaceholder[] {
    const cacheId = md5(JSON.stringify(filters) + JSON.stringify(this.recipe.getWorkingRules()))
    if (this.gridCache && this.gridCache[0] === cacheId) {
      return this.gridCache[1]
    }
    const rules = this.recipe.getWorkingRules()
    const hc = this.parser?.startIndex || 0
    if (filters && filters.length) {
      const filteredData = this.getFilteredData(filters)
      const grid: RowPlaceholder[] = filteredData.map(([, rowIndex]) => [
        rowIndex,
        ...[...Array(rules.length)]
      ])
      this.gridCache = [cacheId, grid]

      return grid
    } else {
      const grid: RowPlaceholder[] = [...Array(this.getLength(true))].map((_v, i) => [
        i + hc,
        ...[...Array(rules.length)]
      ])

      this.gridCache = [cacheId, grid]
      return grid
    }
  }

  /**
   * Returns configuration for a specific view column
   *
   * @param viewCol
   */
  public getConfigForColumn(viewCol: number): [IRule, IField] {
    const rule = this.recipe.getWorkingRules()[viewCol]
    const field = this.recipe.getField(rule.targetKey as string)

    return [rule, field]
  }

  /**
   * Get value for a specific column index
   *
   * @param srcRow
   * @param viewCol
   */
  public getValueAtPoint(srcRow: number, viewCol: number): Nullable<IPrimitive> {
    const original = this.getSourceValueAtPoint(srcRow, viewCol)
    const override = this.getValueOverrideAtPoint(srcRow, viewCol)
    const mutation = this.getMutationAtPoint(srcRow, viewCol)
    const virtualRecordHookMutation = this.getVirtualRecordHookMutationAtPoint(srcRow, viewCol)
    const fieldHookMutation = this.getFieldHookMutationAtPoint(srcRow, viewCol)

    return coalesce(mutation, override, virtualRecordHookMutation, fieldHookMutation, original)
  }

  /**
   * Get the raw original value at a given view point
   *
   * @param row
   * @param viewCol
   */
  public getSourceValueAtPoint(row: number, viewCol: number): Nullable<IPrimitive> {
    const { sourceIndex, optionsMap } = this.recipe.getWorkingRuleAt(viewCol)
    const out = this.data[row]?.[0]?.[sourceIndex]

    if (optionsMap) {
      const normalizedOut = (out ?? '').trim()
      return out !== undefined && optionsMap[normalizedOut] !== undefined
        ? optionsMap[normalizedOut]
        : out
    } else {
      return out
    }
  }

  /**
   * Get the raw original value at a given view point
   *
   * @param row
   * @param viewCol
   */
  public getMutationAtPoint(row: number, viewCol: number): IPrimitive | undefined {
    const { sourceIndex } = this.recipe.getWorkingRuleAt(viewCol)
    return this.validator.getMutationAtPoint(row, sourceIndex)
  }

  /**
   * Get the field hook mutated value at a given view point
   *
   * @param row
   * @param viewCol
   */
  public getFieldHookMutationAtPoint(row: number, viewCol: number): IPrimitive | undefined {
    const { sourceIndex } = this.recipe.getWorkingRuleAt(viewCol)
    return this.validator.getFieldHookMutationAtPoint(row, sourceIndex)
  }

  /**
   * Get the virtual record hook mutated value at a given view point
   *
   * @param row
   * @param viewCol
   */
  public getVirtualRecordHookMutationAtPoint(row: number, viewCol: number): IPrimitive | undefined {
    const { sourceIndex } = this.recipe.getWorkingRuleAt(viewCol)
    return this.validator.getVirtualRecordHookMutationAtPoint(row, sourceIndex)
  }

  /**
   * Returns user modifications (if any) at a given view point
   *
   * @param srcRow
   * @param viewCol
   */
  public getValueOverrideAtPoint(srcRow: number, viewCol: number): Nullable<IPrimitive> {
    const { sourceIndex } = this.recipe.getWorkingRuleAt(viewCol)
    const rowOverride = this.userEdits[srcRow] || []
    return rowOverride[sourceIndex]
  }

  public getStylesAtPoint(srcRow: number, viewCol: number): ICellStyles {
    if (!this.recipe?.getSettings()?.allowFormatting) {
      return {}
    }

    return this.styles[`${convertToLetters(viewCol + 1)}${srcRow + 1}`] ?? {}
  }

  public overrideStylesAtPoint(
    srcRow: number,
    viewCol: number,
    field: keyof ICellStyles | 'clear',
    value: boolean
  ) {
    if (field === 'clear') {
      this.styles[`${convertToLetters(viewCol + 1)}${srcRow + 1}`] = {}
      return
    }

    const formats = this.getStylesAtPoint(srcRow, viewCol)
    formats[field] = value
    this.styles[`${convertToLetters(viewCol + 1)}${srcRow + 1}`] = formats
  }

  /**
   * Converts a given row to a RecordTuple
   * @param srcRow
   */
  public getRecord(srcRow: number): RecordTuple {
    return this.mapRowToRecord(this.data[srcRow])
  }

  /**
   * This a special function to support HandsonTable rendering
   * @param viewCol
   * @param field
   */
  public getValueAccessor(viewCol: number, field: IField) {
    return ([srcRow]: RowPlaceholder, newValue?: Nullable<IPrimitive>) => {
      if (newValue === undefined) {
        // TODO: remove this once we have a proper renderer that can receive the value
        if (field.type === 'select') {
          const value = this.getValueAtPoint(srcRow, viewCol)
          const displayValue = field.options.find((o) => o.value === value)?.label

          debug('GET SELECT VALUE', srcRow, viewCol, displayValue)
          return displayValue
        }
        return this.getValueAtPoint(srcRow, viewCol)
      }
      // TODO: remove this once we have a proper renderer that can receive the value
      // newValue is null if deleted
      if (field?.type === 'select' && newValue !== null) {
        newValue = field.options.find((o) => o.label === newValue)?.value
      }
      debug('SET VALUE', srcRow, viewCol, newValue)
      this.overrideValueAtPoint(srcRow, viewCol, newValue)
      return
    }
  }

  /**
   * Returns percentage of invalid cells per rule (up to DEFAULT_SAMPLE_SIZE rows)
   * @param rule
   */
  public getValidationSummary(rule: IRule): number | undefined {
    if (!this.parser) {
      return
    }

    const data = this.parser.sample.slice(this.parser.startIndex || 0)

    const invalidCount = data.reduce((acc, _row) => {
      const [row, rowIndex] = this.mapRowToRecord(_row, true)
      if (recordIsEmpty([row, rowIndex])) {
        return acc
      }

      row
        .filter(([_, srcIndex]) => srcIndex === rule.sourceIndex)
        .forEach(([_, srcIndex]) => {
          if (this.validator.validateCell([row, rowIndex], srcIndex).length) {
            acc++
          }
        })

      return acc
    }, 0)

    return invalidCount / data.length
  }

  /**
   * Get the number of rows in the dataset
   */
  public getLength(raw: boolean = false): number {
    if (this.parser) {
      const [length] = this.parser.getLength()
      return length - this.parser.startIndex
    }
    return raw
      ? this.localData.length
      : this.localData.filter(
          ([data, idx]) =>
            this.userEdits[idx]?.length || data.reduce((acc, v) => !!v || acc, false as boolean)
        ).length
  }

  public applyFilters(filters: VIEW_FILTER[]) {
    this.filters = filters
  }

  public ignoreRow(rowIndex: number) {
    if (!this.ignored.includes(rowIndex)) {
      this.ignored.push(rowIndex)
    }
  }

  public unIgnoreRow(rowIndex: number) {
    const iii = this.ignored.findIndex((ii) => ii === rowIndex)
    if (iii !== -1) {
      this.ignored.splice(iii, 1)
    }
  }

  /**
   * Return data ready for consumption by the SDK
   *
   * @param start
   * @param length
   */
  public async getAnnotatedOutput(start?: number, length?: number): Promise<IResultRecord[]> {
    const rows =
      start !== undefined && length !== undefined
        ? this.trimmedData.slice(start, start + length)
        : this.trimmedData

    const rules = this.recipe.getWorkingRules()
    const out = await asyncMap(rows, async ([r, i]: RowTuple) => {
      const data = { ...associateRow(rules, this.mapRowToRecord([r, i], true)) }
      return {
        sequence: i,
        deleted: Object.values(data).every((v) => v === undefined || v === '' || v === null),
        edited: this.userEdits[i] !== undefined,
        valid: !this.validator.isRowInvalid(i),
        data
      }
    })

    return out.filter(
      ({ data }) => !Object.values(data).every((v) => v === undefined || v === '' || v === null)
    )
  }

  /**
   * Generate a RecordTuple from any given row
   *
   * @param originalRow
   * @param rowIndex
   * @param final
   */
  public mapRowToRecord([originalRow, rowIndex]: RowTuple, final: boolean = false): RecordTuple {
    const rules = this.recipe.getWorkingRules()
    return [
      rules.map(({ sourceIndex, optionsMap }, viewCol) => {
        const srcValue = originalRow[sourceIndex]

        if (final) {
          const mutation = this.getMutationAtPoint(rowIndex, viewCol)
          if (mutation !== undefined) {
            return [mutation, sourceIndex]
          }
        }
        const override = this.getValueOverrideAtPoint(rowIndex, viewCol)
        if (override !== undefined) {
          return [override, sourceIndex]
        }
        const virtualRecordHookMutation = this.getVirtualRecordHookMutationAtPoint(
          rowIndex,
          viewCol
        )
        if (virtualRecordHookMutation !== undefined) {
          return [virtualRecordHookMutation, sourceIndex]
        }
        const fieldHookMutation = this.getFieldHookMutationAtPoint(rowIndex, viewCol)
        if (fieldHookMutation !== undefined) {
          return [fieldHookMutation, sourceIndex]
        }
        if (optionsMap) {
          const normalizedSource = (srcValue ?? '').trim()
          return [
            optionsMap[normalizedSource] === undefined ? null : optionsMap[normalizedSource],
            sourceIndex
          ]
        }
        return [srcValue, sourceIndex]
      }),
      rowIndex
    ]
  }

  public clearUserEdits(): void {
    this.userEdits = []
  }

  private getFilteredData(filters: VIEW_FILTER[]) {
    const hc = this.parser?.startIndex || 0
    const data = this.data
    return data.filter(([, rowIndex]) => {
      if (rowIndex + 1 <= hc) {
        return false
      }
      return filters.reduce((acc, filter) => {
        switch (filter) {
          case VIEW_FILTER.INVALID:
            return this.validator.isRowInvalid(rowIndex) || acc
          case VIEW_FILTER.MODIFIED:
            return !!(this.userEdits[rowIndex] && this.userEdits[rowIndex].length) || acc
        }
        return acc
      }, false as boolean)
    })
  }

  /**
   * Record a user modification to a value at a given point
   *
   * @param srcRow
   * @param viewCol
   * @param newValue
   */
  private overrideValueAtPoint(
    srcRow: number,
    viewCol: number,
    newValue: Nullable<IPrimitive>
  ): void {
    const baseValue = this.getSourceValueAtPoint(srcRow, viewCol)
    const { sourceIndex } = this.recipe.getWorkingRuleAt(viewCol)
    const rowOverride = this.userEdits[srcRow] || []
    const currentOverride = rowOverride[sourceIndex]
    if (currentOverride !== undefined && baseValue === newValue) {
      delete rowOverride[sourceIndex]
      this.userEdits[srcRow] = rowOverride
    } else {
      rowOverride[sourceIndex] = newValue
      this.userEdits[srcRow] = rowOverride
    }
    if (rowOverride.length === 0 || rowOverride.every((v) => v === undefined)) {
      delete this.userEdits[srcRow]
    }
  }

  /**
   * Generates a new dataview from the recipe provided with empty data. Used primarily
   * to provide the dataview necessary for the initial table
   *
   * @param batchConfig
   * @param recipe
   * @param minRows
   */
  public static makeFromRecipe(
    batchConfig: IBatchConfig,
    recipe: Recipe,
    minRows: number = 20
  ): View {
    const rules = recipe.getWorkingRules()
    const data = makeEmptyData(minRows, rules.length)
    const view = new View(data, new Validator(recipe, batchConfig), recipe)
    view.strictIndexes = false
    return view
  }

  /**
   * Generates a new dataview from a provided source object and goes directly to the edit mode
   *
   * @param batchConfig
   * @param source
   * @param recipe
   */
  public static makeFromInputObjects(
    batchConfig: IBatchConfig,
    source: IInputObject[],
    recipe: Recipe
  ): View {
    const sample = source[0] as IInputObject
    const sampleData = source.reduce((acc, s) => {
      return { ...acc, ...s.data }
    }, {})
    recipe.generateDirectRules(sampleData)
    const rules = recipe.getRules()
    const errData: Tuple<IValidatedCell[], number>[] = []
    const data = source
      .map((obj, i) => {
        if (!obj || typeof obj !== 'object') {
          // tslint:disable-next-line:no-console
          console.warn(
            `[flatfile] skipping row source[${i}] because !source[${i}] || typeof source[${i}] !== 'object'`
          )
          return
        }
        if (!obj.data) {
          // tslint:disable-next-line:no-console
          console.warn(`[flatfile] skipping row source[${i}] because !source[${i}].data`)
          return
        }
        const seq = obj.sequence === undefined ? i : obj.sequence
        const validatedCells =
          Array.isArray(obj.errors) &&
          obj.errors.reduce((acc, err) => {
            const idx = rules.findIndex((rule) => rule.targetKey === err.key)
            if (idx === -1) {
              return acc
            }
            const level =
              err.level === 'warning' ? 'warnings' : err.level === 'info' ? 'info' : 'errors'
            const curr = acc.find((cell) => cell.col === idx)
            if (curr) {
              curr[level]?.push(err.message)
            } else {
              acc.push({
                value: '',
                col: idx,
                errors: [],
                warnings: [],
                info: [],
                [level]: [err.message]
              })
            }
            return acc
          }, [] as IValidatedCell[])

        if (validatedCells) {
          errData.push([validatedCells, seq])
        }
        return [
          rules.map((rule) => (rule.targetKey ? (obj.data[rule.targetKey] as string) : '')),
          seq
        ]
      })
      .filter((x) => x) as RowTuple[] // filtering out the undefined
    const view = new View(data, new Validator(recipe, batchConfig), recipe)
    view.strictIndexes = sample.sequence !== undefined
    errData.forEach(([errs, seq]) => {
      view.validator.cacheValidationState(errs, seq, true)
    })
    return view
  }
}

export enum VIEW_FILTER {
  INVALID,
  MODIFIED,
  WARNINGS
}

export function associateRow(
  rules: IRule[],
  [originalRow]: RecordTuple
): ScalarDictionaryWithCustom {
  return rules.reduce((acc, { isCustom, sourceIndex, targetKey, targetType, virtual }) => {
    const [srcValue] = originalRow.find(([, colIndex]) => colIndex === sourceIndex) as AtomTuple

    if (!targetKey) {
      return acc
    }

    if (virtual && srcValue === undefined) {
      acc[targetKey as string] = ''
      return acc
    }

    if (isCustom || targetKey === '$custom') {
      if (!acc.$custom) {
        acc.$custom = {}
      }

      acc.$custom[targetKey] = srcValue

      return acc
    }

    if (targetType && targetType === 'checkbox') {
      if (srcValue === null) {
        acc[targetKey as string] = null
      } else if (srcValue === true || truthyRegex.test(String(srcValue))) {
        acc[targetKey as string] = true
      } else if (!srcValue || falsyRegex.test(String(srcValue))) {
        acc[targetKey as string] = false
      }
    } else {
      acc[targetKey as string] = srcValue
    }

    return acc
  }, {} as ScalarDictionaryWithCustom)
}

export type RecordTuple = [AtomTuple[], number]
export type AtomTuple = IndexTuple<Nullable<IPrimitive>>

export type RowPlaceholder = [number, ...string[]]

function makeEmptyData(rows: number, cols: number): RowTuple[] {
  return [...Array(rows)].map((_v) => [...Array(cols)].map((_vv) => '')).map((row, i) => [row, i])
}

export type IResultRecord = {
  sequence: number
  deleted: boolean
  edited: boolean
  valid: boolean
  data: ScalarDictionaryWithCustom
}
export type IResultRecordsTuple = [IResultRecord[], number]
