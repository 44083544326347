export class FlatFile {
  constructor(private readonly source: string[][], public readonly name: string) {}

  public get size() {
    return 0
  }

  public stepUntil(step: (rowData: string[], index: number) => boolean | void) {
    this.source.some(step)
  }
}
