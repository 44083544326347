import { diffWords } from 'diff'
import Handsontable from 'handsontable'
import i18next from 'i18next'
import tippy from 'tippy.js'

import { View, VIEW_FILTER } from '../lib/view'
import { falsyRegex, truthyRegex } from './truthy.regex'

// tslint:disable:no-any
export function makeCustomRenderer(view: View): Handsontable.renderers.Base {
  return function customRenderer(this: any, ...args) {
    const [hotInstance, td, row, col, , value] = args
    const filters = view.filters
    let v = value

    // TODO should this really run
    Handsontable.renderers.BaseRenderer.apply(this, args)

    const column = col - 1
    const srcRow = hotInstance.getDataAtCell(row, 0)
    const vState = view.getValidationState(srcRow, column)
    const info = vState.info || []
    const warnings = vState.warnings || []
    const errors = [...vState.errors]
    const srcValue = view.getSourceValueAtPoint(srcRow, column)
    const userEdit = view.getValueOverrideAtPoint(srcRow, column)
    const mutation =
      view.getMutationAtPoint(srcRow, column) || view.getFieldHookMutationAtPoint(srcRow, column)
    const styles = view.getStylesAtPoint(srcRow, column)
    const [, { type }] = view.getConfigForColumn(column)

    if (Object.keys(styles).length) {
      if (styles.bold) {
        td.classList.add('bold')
      }
      if (styles.italic) {
        td.classList.add('italic')
      }
      if (styles.strike) {
        td.classList.add('strike')
      }
    }

    let fragment: DocumentFragment
    if (mutation !== undefined && srcValue !== mutation && !userEdit) {
      td.classList.add('mutated')
      info.push(i18next.t('autoFormatted'))
    }
    if (type !== 'select' && userEdit !== undefined) {
      td.classList.add('userEdited')
      if (
        filters?.includes(VIEW_FILTER.MODIFIED) &&
        typeof (srcValue || '') === 'string' &&
        (typeof userEdit === 'string' || userEdit === null)
      ) {
        const diff = diffWords(srcValue || '', userEdit || '')
        fragment = document.createDocumentFragment()

        diff.forEach((part: any) => {
          const color = part.added ? '#BDE1BD' : part.removed ? 'rgb(250,230,232)' : '#EAEFF5'
          const span = document.createElement('span')
          span.style.backgroundColor = color
          if (part.removed) {
            span.style.textDecoration = 'line-through'
          }
          span.appendChild(document.createTextNode(part.value))
          fragment.appendChild(span)
        })
      }
    }

    if (type === 'checkbox') {
      if (value === true || truthyRegex.test(value)) {
        v = true
        args[5] = v
        Handsontable.renderers.CheckboxRenderer.apply(this, args)
      } else if (!value || falsyRegex.test(value)) {
        v = false
        args[5] = v
        Handsontable.renderers.CheckboxRenderer.apply(this, args)
      } else if (v.length > 0) {
        Handsontable.renderers.TextRenderer.apply(this, args)
      }
    } else if (type === 'select') {
      Handsontable.renderers.DropdownRenderer.apply(this, args)
    } else {
      Handsontable.renderers.TextRenderer.apply(this, args)
      // @ts-ignore
      if (fragment) {
        td.innerHTML = ''
        td.appendChild(fragment)
      }
    }

    // todo: make sure warnings don't impact checkbox rendering

    td.classList.remove('htInvalid')
    if ('_tippy' in td) {
      ;(td as any)._tippy.destroy()
    }
    if (errors.length || warnings.length || info.length) {
      const isError = !!errors.length
      const isWarning = !!warnings.length
      td.classList.remove('mutated,htWarning,htInvalid')
      if (isError) {
        hotInstance.setCellMeta(row, col, 'valid', false)
        td.classList.add('htInvalid')
      } else {
        hotInstance.setCellMeta(row, col, 'valid', true)
        if (isWarning) {
          td.classList.add('htWarning')
        } else {
          td.classList.add('mutated')
        }
      }

      const messages = [...errors, ...warnings, ...info]
      const tipContainer = document.createElement('div')
      tipContainer.classList.add('tooltip-text')
      messages.forEach((msg) => {
        const el = document.createElement('div')

        if (warnings.includes(msg)) {
          el.className = 'warning-tip'
        } else if (info.includes(msg)) {
          el.className = 'info-tip'
        } else {
          el.className = 'error-tip'
        }

        el.innerHTML = msg
        tipContainer.appendChild(el)
      })
      tippy(td, {
        content: tipContainer,
        placement: 'top',
        distance: -3
      })
    } else {
      hotInstance.setCellMeta(row, col, 'valid', true)
    }
    return td
  }
}
