import Ajv from 'ajv'
import betterAjvErrors from 'better-ajv-errors/lib/modern'
import { debug } from 'loglevel'

import endUserSchema from '../schema.endUser.json'
import schema from '../schema.json'
import virtualFieldSchema from '../schema.virtualField.json'
import { IEndUser, IField, ISettings } from '../types/settings.interface'
import { isRawHTML, sanitizeRawHtml } from './misc'

/**
 * Compare the settings input with JSON Schema and error if it does not match
 * the valid structure.
 *
 * @param settings
 */
export function validateSettings(settings?: ISettings): {
  valid: boolean
  errors: string | string[]
} {
  if (!settings) {
    return { valid: false, errors: ['Please provide valid configurations.'] }
  }

  const ajv = new Ajv({ jsonPointers: true })
  const passesSchemaValidation = ajv.validate(schema, settings) as boolean

  if (!passesSchemaValidation) {
    debug('AJV', schema, ajv.errors, ajv.errorsText(ajv.errors))
    debug(betterAjvErrors(schema, settings, ajv.errors, { indent: 2 }))

    return {
      valid: false,
      errors:
        (betterAjvErrors(schema, settings, ajv.errors, {
          indent: 2
        }) as unknown as string[]) || []
    }
  }

  const validFieldKeys = settings.fields.map((field) => field.key)

  const findInvalidFieldKeys = (fields: string[]): [string, number][] =>
    fields.reduce(
      (acc, key, keyIndex) => acc.concat(!validFieldKeys.includes(key) ? [[key, keyIndex]] : []),
      [] as [string, number][]
    )

  const fieldValidationErrors = settings.fields.reduce(
    (errors, field, fieldIndex) =>
      errors.concat(
        field.validators
          ? field.validators.reduce((validatorErrors, validator, validatorIndex) => {
              if (
                validator.validate === 'required_with' ||
                validator.validate === 'required_with_all' ||
                validator.validate === 'required_without' ||
                validator.validate === 'required_without_all'
              ) {
                validatorErrors = validatorErrors.concat(
                  findInvalidFieldKeys(validator.fields).map(([key, keyIndex]) =>
                    betterAjvErrors(
                      schema,
                      settings,
                      [
                        {
                          dataPath: `/fields/${fieldIndex}/validators/${validatorIndex}/fields/${keyIndex}`,
                          keyword: 'fields',
                          message: `cannot include non-existent field "${key}" (fields[${fieldIndex}] with key "${field.key}")`
                        }
                      ],
                      {
                        indent: 2
                      }
                    )
                  )
                )
              } else if (
                validator.validate === 'required_with_values' ||
                validator.validate === 'required_with_all_values' ||
                validator.validate === 'required_without_values' ||
                validator.validate === 'required_without_all_values'
              ) {
                validatorErrors = validatorErrors.concat(
                  findInvalidFieldKeys(Object.keys(validator.fieldValues)).map(([key]) =>
                    betterAjvErrors(
                      schema,
                      settings,
                      [
                        {
                          dataPath: `/fields/${fieldIndex}/validators/${validatorIndex}/fieldValues/${key}`,
                          keyword: 'fieldValues',
                          isIdentifierLocation: true,
                          message: `cannot include non-existent field "${key}" (fields[${fieldIndex}] with key "${field.key}")`
                        }
                      ],
                      {
                        indent: 2
                      }
                    )
                  )
                )
              } else if (
                validator.validate === 'regex_matches' ||
                validator.validate === 'regex_excludes'
              ) {
                try {
                  // tslint:disable-next-line: no-unused-expression
                  new RegExp(validator.regex)
                } catch ({ message }) {
                  validatorErrors = validatorErrors.concat(
                    betterAjvErrors(
                      schema,
                      settings,
                      [
                        {
                          dataPath: `/fields/${fieldIndex}/validators/${validatorIndex}/regex`,
                          keyword: '',
                          message: `${message}" (fields[${fieldIndex}] with key "${field.key}")`
                        }
                      ],
                      {
                        indent: 2
                      }
                    )
                  )
                }
              }

              return validatorErrors
            }, [] as string[])
          : []
      ),
    [] as string[]
  )

  if (fieldValidationErrors.length > 0) {
    return {
      valid: false,
      errors: fieldValidationErrors
    }
  }

  const findInvalidHTMLOverrides = (
    messages: Record<string, string | object>
  ): { key: string; message?: string }[] => {
    return Object.entries(messages).reduce((acc, [key, override]) => {
      if (typeof override === 'object') {
        return acc.concat(
          findInvalidHTMLOverrides((override || {}) as Record<string, string>).map(
            ({ key: fieldKey, message }) => ({ key: `${key}/${fieldKey}`, message })
          )
        )
      } else if (typeof override === 'string') {
        if (isRawHTML(override) && sanitizeRawHtml(override) !== override) {
          acc.push({ key, message: 'HTML is invalid' })
        }
      } else {
        acc.push({ key, message: 'Override value type should be string.' })
      }
      return acc
    }, [] as { key: string; message?: string }[])
  }

  const i18nValidationErrors =
    settings.i18nOverrides && Object.keys(settings.i18nOverrides).length
      ? Object.entries(settings.i18nOverrides).reduce((errors, [locale, lang]) => {
          if (lang && typeof lang.overrides === 'object') {
            return errors.concat(
              findInvalidHTMLOverrides(lang.overrides).map(({ key, message }) =>
                betterAjvErrors(
                  schema,
                  settings,
                  [
                    {
                      dataPath: `/i18nOverrides/${locale}/overrides/${key}`,
                      keyword: '',
                      message
                    }
                  ],
                  {
                    indent: 2
                  }
                )
              )
            )
          }

          return errors
        }, [] as string[])
      : []

  if (i18nValidationErrors.length > 0) {
    return {
      valid: false,
      errors: i18nValidationErrors
    }
  }

  return {
    valid: true,
    errors: []
  }
}

export const validateEndUser = (endUser?: IEndUser): boolean => {
  const ajv = new Ajv({ jsonPointers: true })
  return !endUser ? false : (ajv.validate(endUserSchema, endUser) as boolean)
}

export const validateVirtualField = (payload = {}): boolean => {
  const ajv = new Ajv({ jsonPointers: true })
  if (!(ajv.validate(virtualFieldSchema, payload) as boolean)) {
    // tslint:disable-next-line: no-console
    console.error(
      `[Flatfile] Invalid virtual field: ${betterAjvErrors(
        virtualFieldSchema,
        payload,
        ajv.errors,
        { indent: 2 }
      )}`
    )
    return false
  }

  return true
}

/**
 * Compare any value against a regex string
 *
 * @param regexString
 * @param value
 * @param flags
 * @param fallback
 */
export function testRegex(
  regexString: string,
  flags: string,
  // tslint:disable-next-line:no-any
  value: any,
  fallback: boolean = true
): boolean {
  const regex = new RegExp(regexString, flags)
  if (typeof value === 'string') {
    return regex.test(value)
  } else {
    return fallback
  }
}

/**
 *
 * @param field
 */
export function isFieldRequired(field: IField) {
  return field.validators?.find((v) => v.validate === 'required')
}
