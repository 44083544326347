import { IRegexFlags } from '../types/settings.interface'

const flagMap: { [key in keyof IRegexFlags]: string } = {
  dotAll: 's',
  ignoreCase: 'i',
  multiline: 'm',
  global: 'g',
  unicode: 'u'
}

export const getRegexFlags = (source?: IRegexFlags): string =>
  Object.keys(flagMap)
    .filter((flagKey) => source && source[flagKey as keyof IRegexFlags])
    .map((flagKey) => flagMap[flagKey as keyof IRegexFlags])
    .join('')
