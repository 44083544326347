import React, { FC, SVGProps } from 'react'

export const MobileNotAllowedIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38 66' {...props}>
    <path
      fillRule='evenodd'
      d='M38 59.006A6.992 6.992 0 0130.995 66H7.005C3.14 66 0 62.866 0 59.008V6.992A6.991 6.991 0 017.005 0h23.99C34.86 0 38 3.138 38 6.994v52.012zM36 14V6.994C36 4.243 33.756 2 30.995 2H7.005A4.991 4.991 0 002 6.992V14h34zM2 59.008C2 61.76 4.243 64 7.005 64h23.99A4.992 4.992 0 0036 59.006V52H2v7.008zM2 50h34V16H2v34zM16 9a1 1 0 110-2h6a1 1 0 110 2h-6zm3 51a2 2 0 110-4 2 2 0 010 4zm0-28.414l5.293-5.293a1 1 0 011.414 1.414L20.414 33l5.293 5.293a1 1 0 01-1.414 1.414L19 34.414l-5.293 5.293a1 1 0 01-1.414-1.414L17.586 33l-5.293-5.293a1 1 0 011.414-1.414L19 31.586z'
    />
  </svg>
)

export const DownIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' {...props}>
    <path d='M43 16c-13.439295 0-23.049946 11.2898-23.0625 23.3125C11.412587 40.82269 5 48.4397 5 57c0 10.8653 8.41566 19 18 19h12c1.584938.02241 3.042726-1.414904 3.042726-3S36.584938 69.977585 35 70H23c-6.21498 0-12-5.1737-12-13 0-6.1395 5.441794-12 12-12 1.717366.000272 3.219002-1.738465 2.96875-3.4375C24.634479 32.06124 32.079557 22 43 22c7.377682 0 12.78385 4.28643 15.3125 9.34375.712769 1.39808 2.631986 2.022941 4.03125 1.3125 3.060852-1.53043 6.749308-1.31916 9.625.40625S77 37.91667 77 43c.0097 1.39646 1.123053 2.704668 2.5 2.9375C84.96595 46.85461 89 52.443 89 58c0 6.6895-5.143067 12-14 12H65c-1.584938-.02241-3.042726 1.414904-3.042726 3S63.415062 76.022415 65 76h10c11.480751 0 20-8.0301 20-18 0-7.6314-4.828348-15.01099-12.28125-17.375-.68248-5.73952-3.622648-10.27984-7.6875-12.71875-3.863917-2.31835-8.521242-2.77654-12.84375-1.46875C58.322886 20.57389 51.621681 16 43 16zm-1.34375 45.96875c-1.123172.120413-2.143335.931216-2.514182 1.998215-.370848 1.066999-.07345 2.335734.732932 3.126785L45.75 73l-5.875 5.875c-1.223497 1.084361-1.283954 3.222953-.123672 4.374704C40.317973 83.812183 41.209819 84 42 84c.770415 0 1.574842-.276193 2.125-.90625l5.875-5.875 5.875 5.875C56.414439 83.711531 57.229941 84 58 84c.790248 0 1.67046-.176336 2.248672-.750296 1.160282-1.15175 1.099825-3.290343-.123672-4.374704L54.25 73l5.875-5.90625c.892842-.870601 1.14962-2.326705.608429-3.450192-.541192-1.123487-1.839872-1.830316-3.077179-1.674808-.673325.088781-1.313065.414262-1.78125.90625L50 68.75l-5.875-5.875c-.628627-.654397-1.566034-.998508-2.46875-.90625z' />
  </svg>
)

export const InfoIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
    <defs />
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0H24V24H0z' />
      <circle cx='12' cy='12' r='10' fill='#AFBED0' fillRule='nonzero' opacity='.3' />
      <rect width='2' height='7' x='11' y='10' fill='#3F5C71' fillRule='nonzero' rx='1' />
      <rect width='2' height='2' x='11' y='7' fill='#3F5C71' fillRule='nonzero' rx='1' />
    </g>
  </svg>
)

export const QuestionIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <circle cx='8' cy='8' r='8' fill='#4E5A65'></circle>
      <path
        fill='#fff'
        d='M7.2 9.56h1.38c0-1.296 2.112-1.788 2.112-3.612 0-1.512-1.296-2.304-2.628-2.304-1.224 0-2.328.6-2.784 1.776l1.188.672c.24-.708.804-1.104 1.56-1.104.768 0 1.284.348 1.284 1.044C9.312 7.34 7.2 7.796 7.2 9.56zm.696 2.784a.899.899 0 100-1.8c-.504 0-.9.396-.9.9 0 .492.396.9.9.9z'
      ></path>
    </svg>
  )
}

export const ErrorsDiscovered: FC<SVGProps<SVGSVGElement>> = (props?) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    data-name='layer 1'
    viewBox='0 0 128 128'
    {...props}
  >
    <circle cx='118' cy='18' r='2' />
    <circle cx='110' cy='18' r='2' />
    <circle cx='102' cy='18' r='2' />
    <path d='M122 8H6a6 6 0 00-6 6v84a6 6 0 006 6h46v12H32v4h64v-4H76v-12h46a6 6 0 006-6V14a6 6 0 00-6-6zm2 90a2 2 0 01-2 2H6a2 2 0 01-2-2V14a2 2 0 012-2h116a2 2 0 012 2z' />
    <path d='M34 67.17l-4 4-4-4L23.17 70l4 4-4 4L26 80.83l4-4 4 4L36.83 78l-4-4 4-4L34 67.17zM29.53 41.92l-4.15-4.15-2.83 2.83 6.98 6.98 9.16-9.16-2.83-2.83-6.33 6.33zM44 40h60v4H44zM44 56h40v4H44zM44 72h60v4H44zM29.53 58.17l-4.15-4.15-2.83 2.82 6.98 6.99 9.16-9.16-2.83-2.83-6.33 6.33z' />
  </svg>
)

export const NoEndUser: FC<SVGProps<SVGSVGElement>> = (props?) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' {...props}>
    <path
      // style='text-indent:0;text-transform:none;block-progression:tb'
      d='M43 16c-18.213 0-33 14.786-33 33s14.787 33 33 33a32.878 32.878 0 0019.656-6.5C65.365 79.418 69.886 82 75 82c8.272 0 15-6.728 15-15 0-7.988-6.277-14.527-14.156-14.969.093-1 .156-2.008.156-3.031 0-18.214-14.786-33-33-33zm0 2c17.133 0 31 13.867 31 31 0 1.032-.062 2.056-.156 3.063-4.291.33-8.07 2.467-10.594 5.656-1.169-1.638-2.87-3.04-4.906-4.188C54.353 51.283 48.964 50 43 50c-5.963 0-11.353 1.283-15.344 3.531C23.666 55.78 21 59.101 21 63v7.875A30.922 30.922 0 0112 49c0-17.133 13.867-31 31-31zm0 6c-6.063 0-11 4.937-11 11s4.937 11 11 11 11-4.937 11-11-4.937-11-11-11zm0 2c4.982 0 9 4.018 9 9s-4.018 9-9 9-9-4.018-9-9 4.018-9 9-9zm0 26c5.67 0 10.774 1.253 14.375 3.281 2.143 1.207 3.7 2.647 4.625 4.25a14.948 14.948 0 00-.375 14.25A30.858 30.858 0 0143 80a30.856 30.856 0 01-20-7.312V63c0-2.994 2.025-5.69 5.625-7.719C32.225 53.253 37.329 52 43 52zm32 2c7.192 0 13 5.808 13 13s-5.808 13-13 13c-7.191 0-13-5.808-13-13s5.809-13 13-13zm0 2c-1.358 0-2.647.273-3.719.875-1.072.603-1.939 1.561-2.25 2.781a1 1 0 101.938.5c.155-.608.572-1.132 1.281-1.531.71-.399 1.693-.625 2.75-.625 2.27 0 4.011 1.783 4 3-.02 2.199-1.026 3.193-2.281 4.625C75.463 67.057 74 68.918 74 72a1 1 0 102 0c0-2.49 1.017-3.625 2.25-5.031C79.483 65.562 80.974 63.852 81 61c.025-2.747-2.75-5-6-5zm0 19a1.5 1.5 0 100 3 1.5 1.5 0 000-3z'
      overflow='visible'
      color='#000'
    />
  </svg>
)
export const InfoIconString = `
  <svg height='12' width='12' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' class="info-icon">
    <path
      fill='#9FADB7'
      d='M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z'
    />
  </svg>
`
export const RequiredIconString = `
  <svg height='10' width='10' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" class="required-icon">
    <path d="M6 10H4V6.732L1.17 8.367l-1-1.732L3 5 .17 3.367l1-1.733L4 3.268V0h2v3.269l2.83-1.635 1 1.733L7 5l2.83 1.634-1 1.732L6 6.732z"/>
  </svg>
`

export function FormatStrikeIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z'></path>
      <path fill='currentColor' d='M10 19h4v-3h-4v3zM5 4v3h5v3h4V7h5V4H5zM3 14h18v-2H3v2z'></path>
    </svg>
  )
}

export function FormatClearIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z'></path>
      <path
        fill='currentColor'
        d='M3.27 5L2 6.27l6.97 6.97L6.5 19h3l1.57-3.66L16.73 21 18 19.73 3.55 5.27 3.27 5zM6 5v.18L8.82 8h2.4l-.72 1.68 2.1 2.1L14.21 8H20V5H6z'
      ></path>
    </svg>
  )
}

export function FormatItalicIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z'></path>
      <path fill='currentColor' d='M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z'></path>
    </svg>
  )
}

export function FormatBoldIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z'></path>
      <path
        fill='currentColor'
        d='M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z'
      ></path>
    </svg>
  )
}
