import i18next from 'i18next'
import chunk from 'lodash/chunk'
import uniq from 'lodash/uniq'
import { v4 as uuid } from 'uuid'

import { RECORD_HOOK_BATCH_ROWS } from '../config'
import {
  IDataHookResponse,
  IDictionary,
  INumberDictionary,
  IPrimitive,
  Nullable,
  Scalar,
  ScalarDictionary,
  ScalarDictionaryWithCustom
} from '../types/general.interface'
import { IBatchConfig, IValidator } from '../types/settings.interface'
import { emitAndWait, hasListeners } from '../utils/event.manager'
import { asyncForEach } from '../utils/iterators'
import { memo } from '../utils/memo'
import { drillMergeArray, drillSet, isEmpty } from '../utils/misc'
import { getRegexFlags } from '../utils/regex.flags'
import { testRegex } from '../utils/settings.helpers'
import { falsyRegex, truthyRegex } from '../utils/truthy.regex'
import { IndexTuple } from './file.parser'
import { Recipe } from './recipe'
import { associateRow, AtomTuple, RecordTuple, View } from './view'

const getRegexFlagsMemo = memo(getRegexFlags)

export class Validator {
  public rowsPending: number[] = []
  private errorRows: boolean[] = []

  private columnsInvalid: INumberDictionary = {}

  private errorMessages: string[] = []

  private errors: number[][][] = []
  private warnings: number[][][] = []
  private info: number[][][] = []
  private uniques: IDictionary<number[]>[] = []
  private uniqueLastValidated: string[][] = []

  private mutations: IPrimitive[][] = []
  private fieldHookMutations: IPrimitive[][] = []
  private virtualRecordHookMutations: IPrimitive[][] = []
  private sequenceRef?: string

  // tslint:disable-next-line:no-any
  constructor(private recipe: Recipe, private batchConfig: IBatchConfig) {}

  public getRowValidation(record: RecordTuple): IValidatedRow {
    return this.expandCachedValidationState(record)
  }

  public isRowInvalid(rowIndex: number): boolean {
    return this.errorRows[rowIndex]
  }

  public countErrorRows(): number {
    return this.errorRows.reduce((acc, v) => (v ? ++acc : acc), 0)
  }

  /**
   * Returns mutations (if any) at a given view point
   *
   * @param srcRow
   * @param srcIndex
   */
  public getMutationAtPoint(srcRow: number, srcIndex: number): IPrimitive | undefined {
    return (this.mutations[srcRow] || [])[srcIndex]
  }

  /**
   * Returns field hook mutations (if any) at a given view point
   *
   * @param srcRow
   * @param srcIndex
   */
  public getFieldHookMutationAtPoint(srcRow: number, srcIndex: number): IPrimitive | undefined {
    return (this.fieldHookMutations[srcRow] || [])[srcIndex]
  }

  /**
   * Returns virtual record hook mutations (if any) at a given view point
   *
   * @param srcRow
   * @param srcIndex
   */
  public getVirtualRecordHookMutationAtPoint(
    srcRow: number,
    srcIndex: number
  ): IPrimitive | undefined {
    return (this.virtualRecordHookMutations[srcRow] || [])[srcIndex]
  }

  public async validateRow(
    [row, rowIndex]: RecordTuple,
    initial: boolean = false,
    result?: IDataHookResponse
  ): Promise<IValidatedRow> {
    const rules = this.recipe.getWorkingRules()
    let rowValid = true
    const isNullRecord = recordIsEmpty([row, rowIndex])
    const callbackErrors: string[][] = []
    const callbackWarnings: string[][] = []
    const callbackInfo: string[][] = []

    if (!initial) {
      delete this.mutations[rowIndex]
      delete this.errors[rowIndex]
      delete this.warnings[rowIndex]
      delete this.info[rowIndex]
    }
    if (
      ((!initial && hasListeners('record:change')) ||
        (initial && hasListeners('record:init')) ||
        (initial && result)) &&
      !isNullRecord
    ) {
      if (!result) {
        const data = associateRow(rules, [row, rowIndex])
        result = await emitAndWait(initial ? 'record:init' : 'record:change', {
          data,
          sequence: rowIndex
        })
      }

      row = row.map(([value, srcIndex], i) => {
        const rule = rules[i]
        const key = rule.targetKey as string
        const messages = result?.[key]?.info || []
        const errors = messages.filter((e) => e.level === 'error').map((err) => err.message)
        const warnings = messages.filter((e) => e.level === 'warning').map((err) => err.message)
        const info = messages.filter((e) => e.level === 'info').map((err) => err.message)
        const resValue = result?.[key]?.value
        if (errors.length) {
          rowValid = false
        }
        if (resValue !== undefined) {
          drillSet(this.mutations, rowIndex, srcIndex, resValue)
        }
        callbackErrors[srcIndex] = errors
        callbackWarnings[srcIndex] = warnings
        callbackInfo[srcIndex] = info
        return [resValue !== undefined ? resValue : value, srcIndex]
      })
    }

    if (isNullRecord) {
      this.removeUniqueIndex(rowIndex)
    }

    const associatedRow = associateRow(rules, [row, rowIndex])
    const newRow: IValidatedRow = row.map(([value, srcIndex]): IValidatedCell => {
      const errors = isNullRecord ? [] : this.validateCell([row, rowIndex], srcIndex, associatedRow)
      if (errors.length) {
        rowValid = false
      }
      return {
        col: srcIndex,
        value,
        info: [...(callbackInfo[srcIndex] || [])],
        warnings: [...(callbackWarnings[srcIndex] || [])],
        errors: [...errors, ...(callbackErrors[srcIndex] || [])]
      }
    })

    this.errorRows[rowIndex] = initial ? this.errorRows[rowIndex] || !rowValid : !rowValid

    this.cacheValidationState(newRow, rowIndex)
    return newRow
  }

  public validateCell1(_tuple: RecordTuple, _srcIndex: number) {
    return []
  }

  public validateCell(
    [values, rowIndex]: RecordTuple,
    srcIndex: number,
    associatedRow?: ScalarDictionaryWithCustom
  ) {
    const [value] = values.find(([, i]) => i === srcIndex) as AtomTuple
    let row: ScalarDictionaryWithCustom
    if (!associatedRow) {
      const rules = this.recipe.getWorkingRules()
      row = associateRow(rules, [values, rowIndex])
    } else {
      row = associatedRow
    }
    const column = this.recipe.getFieldSettings(srcIndex)
    if (!column) {
      return []
    }
    const validators = column.validators || []

    const requiredValidatorKeys = [
      'required',
      'required_without',
      'required_without_all',
      'required_with_all',
      'required_with',
      'required_without_values',
      'required_without_all_values',
      'required_with_all_values',
      'required_with_values'
    ]

    const requiredValidators = validators.filter((validator) =>
      requiredValidatorKeys.includes(validator.validate)
    )

    const requiredErrors = requiredValidators.reduce((errors, validator) => {
      let isValid = true

      switch (validator.validate) {
        case 'required':
          isValid = !isEmpty(value)
          break
        case 'required_without_all':
          // required when all of the fields are empty
          // i.e. allow empty when any field is non empty
          isValid =
            !isEmpty(value) || validator.fields.some((v) => !isEmpty(this.getRowValue(row, v)))
          break
        case 'required_without':
          // required when any of the fields are empty
          // i.e. allow empty when all fields are non empty
          isValid =
            !isEmpty(value) || !validator.fields.some((v) => isEmpty(this.getRowValue(row, v)))
          break
        case 'required_with_all':
          // required when all of the fields are non empty
          // i.e. allow empty when any field is empty
          isValid =
            !isEmpty(value) || validator.fields.some((v) => isEmpty(this.getRowValue(row, v)))
          break
        case 'required_with':
          // required when any of the fields are non empty
          // i.e. allow empty when all fields are empty
          isValid =
            !isEmpty(value) || !validator.fields.some((v) => !isEmpty(this.getRowValue(row, v)))
          break
        case 'required_without_all_values':
          isValid =
            !isEmpty(value) ||
            // required when all of the fields are mismatched
            // i.e. allow empty when any field is matched
            this.someFields(
              validator.fieldValues,
              (expected, cellValue) =>
                Array.isArray(expected) ? expected.includes(cellValue) : expected === cellValue,
              row
            )
          break
        case 'required_without_values':
          isValid =
            !isEmpty(value) ||
            // required when any of the fields are mismatched
            // i.e. allow empty when all fields are matched
            !this.someFields(
              validator.fieldValues,
              (expected, cellValue) =>
                Array.isArray(expected) ? !expected.includes(cellValue) : expected !== cellValue,
              row
            )
          break
        case 'required_with_all_values':
          isValid =
            !isEmpty(value) ||
            // required when all of the fields are matched
            // i.e. allow empty when any field is mismatched
            this.someFields(
              validator.fieldValues,
              (expected, cellValue) =>
                Array.isArray(expected) ? !expected.includes(cellValue) : expected !== cellValue,
              row
            )
          break
        case 'required_with_values':
          isValid =
            !isEmpty(value) ||
            // required when any of the fields are matched
            // i.e. allow empty when all fields are mismatched
            !this.someFields(
              validator.fieldValues,
              (expected, cellValue) =>
                Array.isArray(expected) ? expected.includes(cellValue) : expected === cellValue,
              row
            )
          break
        default:
          // tslint:disable-next-line:no-console
          console.error(`unhandled required validator: ${validator.validate}`)
      }

      return isValid
        ? errors
        : errors.concat([
            validator.error || this.defaultErrorMessage(validator) || 'Failed validation'
          ])
    }, [] as string[])

    const additionalValidators = validators.filter(
      (validator) => !requiredValidatorKeys.includes(validator.validate)
    )

    const additionalErrors = additionalValidators.reduce((errors, validator) => {
      let isValid = true

      switch (validator.validate) {
        case 'regex_matches':
          isValid = testRegex(
            validator.regex,
            validator.regexFlags ? getRegexFlagsMemo(validator.regexFlags) : '',
            value
          )
          break
        case 'regex_excludes':
          isValid = !testRegex(
            validator.regex,
            validator.regexFlags ? getRegexFlagsMemo(validator.regexFlags) : '',
            value,
            false
          )
          break
        case 'unique':
          if (value !== undefined) {
            const sig = value?.toString() ?? ''

            const hasPresence = this.uniques[srcIndex]?.[sig]?.filter((idx) => idx !== rowIndex)

            const lastValue = this.uniqueLastValidated[srcIndex]?.[rowIndex]

            if (!this.uniqueLastValidated[srcIndex]) {
              this.uniqueLastValidated[srcIndex] = []
            }
            if (lastValue !== sig) {
              if (!this.uniques[srcIndex]) {
                this.uniques[srcIndex] = {}
              }
              if (!this.uniques[srcIndex][sig]) {
                this.uniques[srcIndex][sig] = [rowIndex]
              } else {
                this.uniques[srcIndex][sig].push(rowIndex)
              }
              if (lastValue !== undefined) {
                this.uniques[srcIndex][lastValue] = this.uniques[srcIndex][lastValue].filter(
                  (v) => v !== rowIndex
                )
                if (
                  this.uniques[srcIndex][lastValue].length ||
                  this.uniques[srcIndex][sig].length
                ) {
                  this.rowsPending = this.rowsPending
                    .concat(this.uniques[srcIndex][lastValue])
                    .concat(this.uniques[srcIndex][sig])
                }
              }
            }
            const isFirst = this.uniques[srcIndex]?.[sig]?.sort((a, b) => a - b)[0] === rowIndex
            this.uniqueLastValidated[srcIndex][rowIndex] = sig
            isValid = isFirst || !hasPresence?.length
          }
          break
        case 'select':
          if (value) {
            isValid =
              column.type === 'select'
                ? column.options.find((o) => o.value === value || o.label === value) !== undefined
                : false
          } else {
            isValid = true
          }
          break
        default:
          // tslint:disable-next-line:no-console
          console.error(`unhandled validator: ${validator.validate}`)
      }

      return isValid
        ? errors
        : errors.concat([
            validator.error || this.defaultErrorMessage(validator) || 'Failed validation'
          ])
    }, [] as string[])

    if (isEmpty(value) && requiredErrors.length === 0) {
      return []
    }
    if (column.type === 'checkbox' && typeof value === 'string') {
      if (!truthyRegex.test(value) && !falsyRegex.test(value)) {
        additionalErrors.push(i18next.t('errors.boolean'))
      }
    }
    return requiredErrors.concat(additionalErrors)
  }

  /**
   * This method is used for updating values / warnings / errors using requestCorrectionsFromUser()
   * @param view
   * @param corrections
   */
  public async updateSourceAndValidateState(
    view: View,
    corrections: IDataHookResponse[]
  ): Promise<void> {
    const data = view.trimmedData
      .map((row) => {
        return view.mapRowToRecord(row, true)
      })
      .filter((row) => !recordIsEmpty(row))

    await asyncForEach(data, async ([originalRow, idx], i) => {
      if (corrections[i]) {
        await this.validateRow([originalRow, idx], true, corrections[i]).then((validatedRow) => {
          validatedRow.forEach((vCell: IValidatedCell) => {
            const key = this.recipe.getFieldSettings(vCell.col)?.key as string
            const increment = vCell.errors.length ? 1 : 0
            this.columnsInvalid[key] = (this.columnsInvalid[key] || 0) + increment
          })
        })
      }
    })
  }

  public async validateState(view: View): Promise<void> {
    this.columnsInvalid = {}
    this.sequenceRef = uuid()
    this.mutations = []
    this.fieldHookMutations = []
    this.virtualRecordHookMutations = []
    this.errorRows = []
    this.errors = []
    this.warnings = []
    this.info = []

    if (this.batchConfig.fieldHooks?.length) {
      const workingRules = this.recipe.getWorkingRules()
      await Promise.all(
        this.batchConfig.fieldHooks.map((field): Promise<void> => {
          const ruleTuple = this.recipe.getRuleByKey(field)

          if (!ruleTuple || !hasListeners('field:init')) {
            return Promise.resolve()
          }

          const [rule] = ruleTuple

          // Don't call fieldHook on ignored columns
          if (!workingRules.some((r) => r.sourceIndex === rule.sourceIndex)) {
            return Promise.resolve()
          }

          const columnData = view.trimmedData
            .map((row) => view.mapRowToRecord(row, true))
            .map(
              ([row, index]): IndexTuple<Scalar> => [
                row.find(([, i]) => rule.sourceIndex === i)?.[0] ?? '',
                index
              ]
            )

          return new Promise(async (resolve) => {
            const validationState =
              (await emitAndWait(
                'field:init',
                {
                  data: columnData,
                  meta: { field }
                },
                { id: this.sequenceRef!, index: 0, length: 1 }
              )) || []

            const errMessages: IDictionary<true> = {}

            validationState.forEach(([res]) => {
              const { info } = res
              const messages = info || []
              messages.forEach((v) => {
                errMessages[v.message] = true
              })
            })
            const map = this.getErrorMap(Object.keys(errMessages))

            validationState.forEach(([res, srcRow]) => {
              const { info: rawInfo, value } = res
              const info = rawInfo || []
              const errors = info.filter((v) => v.level === 'error').map((v) => v.message)
              this.cacheAtomInfo(map, srcRow, rule.sourceIndex, {
                info: info.filter((v) => v.level === 'info').map((v) => v.message),
                warnings: info.filter((v) => v.level === 'warning').map((v) => v.message),
                errors
              })

              if (value !== undefined) {
                drillSet(this.fieldHookMutations, srcRow, rule.sourceIndex, value)
              }
              if (errors?.length) {
                this.errorRows[srcRow] = true
              }
            })

            resolve()
          })
        })
      )
    }

    this.recipe.rejectVirtualFields() // virtual fields cannot be added after this point

    if (hasListeners('virtual:record:init:batch') && this.recipe.getVirtualRules().length) {
      const rules = this.recipe.getWorkingRules()

      const allData = view.trimmedData
        .map((row) => {
          return view.mapRowToRecord(row, true)
        })
        .filter((row) => !recordIsEmpty(row))

      await asyncForEach(chunk(allData, RECORD_HOOK_BATCH_ROWS), async (data) => {
        const results = await emitAndWait('virtual:record:init:batch', {
          data: data.map(
            ([originalRow, idx]) =>
              [associateRow(rules, [originalRow, idx]), idx] as [ScalarDictionaryWithCustom, number]
          )
        })

        data.forEach(([row, rowIndex], index) => {
          row.forEach(([_value, srcIndex], i) => {
            const rule = rules[i]

            if (!rule.virtual) {
              return
            }

            const key = rule.targetKey as string
            const resValue = results?.[index]?.[key]?.value

            if (resValue) {
              drillSet(this.virtualRecordHookMutations, rowIndex, srcIndex, resValue)
            }
          })
        })
      })
    }

    if (hasListeners('record:init:batch')) {
      const rules = this.recipe.getWorkingRules()

      const allData = view.trimmedData
        .map((row) => {
          return view.mapRowToRecord(row, true)
        })
        .filter((row) => !recordIsEmpty(row))

      await asyncForEach(chunk(allData, RECORD_HOOK_BATCH_ROWS), async (data) => {
        const results = await emitAndWait('record:init:batch', {
          data: data.map(
            ([originalRow, idx]) =>
              [associateRow(rules, [originalRow, idx]), idx] as [ScalarDictionaryWithCustom, number]
          )
        })

        await Promise.all(
          data.map(([originalRow, idx], i) => {
            return this.validateRow([originalRow, idx], true, results[i]).then((validatedRow) => {
              validatedRow.forEach((vCell: IValidatedCell) => {
                const key = this.recipe.getFieldSettings(vCell.col)?.key as string
                const increment = vCell.errors.length ? 1 : 0
                this.columnsInvalid[key] = (this.columnsInvalid[key] || 0) + increment
              })
            })
          })
        )
      })
    } else {
      await asyncForEach(view.trimmedData, async (row) => {
        const [originalRow, idx] = view.mapRowToRecord(row, true)
        const validatedRow = await this.validateRow([originalRow, idx], true)
        validatedRow.forEach((vCell: IValidatedCell) => {
          const key = this.recipe.getFieldSettings(vCell.col)?.key as string
          const increment = vCell.errors.length ? 1 : 0
          this.columnsInvalid[key] = (this.columnsInvalid[key] || 0) + increment
        })
      })
    }
  }

  public cacheValidationState(
    row: Pick<IValidatedCell, 'col' | 'errors' | 'info' | 'warnings'>[],
    idx: number,
    setErrorRows: boolean = false
  ) {
    const errors = row.reduce((acc, cell) => {
      return [...acc, ...cell.errors, ...(cell.info || []), ...(cell.warnings || [])]
    }, [] as string[])
    const map = this.getErrorMap(uniq(errors))
    row.forEach((cell) => {
      this.cacheAtomInfo(map, idx, cell.col, {
        info: cell.info || [],
        warnings: cell.warnings || [],
        errors: cell.errors || []
      })
    })
    if (setErrorRows) {
      this.errorRows[idx] = true
    }
  }

  /**
   * @param row
   * @param key
   */
  private getRowValue(row: ScalarDictionaryWithCustom, key: string) {
    if (row.$custom && key in row.$custom) {
      return row.$custom[key]
    }
    return row[key] as Nullable<IPrimitive>
  }

  /**
   * check some fields against an expectation i.e. { "key": "value" } // i.e. row["key"] === "value"
   * @param expectedFields
   * @param rule
   * @param row
   */
  private someFields(
    expectedFields: ScalarDictionary,
    rule: (expected: Scalar, value: Scalar) => boolean,
    row: ScalarDictionaryWithCustom
  ) {
    return Object.keys(expectedFields).some((fieldKey) =>
      rule(expectedFields[fieldKey], this.getRowValue(row, fieldKey))
    )
  }

  private cacheAtomInfo(
    map: IDictionary<number>,
    srcRow: number,
    srcCol: number,
    { info, errors, warnings }: IDictionary<string[]>
  ) {
    drillMergeArray(
      this.errors,
      srcRow,
      srcCol,
      errors.map((err) => map[err])
    )
    drillMergeArray(
      this.info,
      srcRow,
      srcCol,
      info.map((err) => map[err])
    )
    drillMergeArray(
      this.warnings,
      srcRow,
      srcCol,
      warnings.map((err) => map[err])
    )
  }

  private expandCachedValidationState([row, rowIndex]: RecordTuple): IValidatedRow {
    const errs = this.errors[rowIndex] || []
    return row.map(([value, cellIdx]) => {
      const cellErrs = errs[cellIdx] || []
      const errors = cellErrs.map((errIdx) => this.errorMessages[errIdx] || 'Invalid')
      const info = this.info[rowIndex]?.[cellIdx]?.map((errIdx) => this.errorMessages[errIdx] || '')
      const warnings = this.warnings[rowIndex]?.[cellIdx]?.map(
        (errIdx) => this.errorMessages[errIdx] || 'Unknown Warning'
      )
      return { col: cellIdx, value, errors, info, warnings }
    })
  }

  private getErrorMap(errors: string[]): INumberDictionary {
    return errors.reduce((acc, err) => {
      let idx = this.errorMessages.findIndex((msg) => msg === err)
      if (idx === -1) {
        this.errorMessages.push(err)
        idx = this.errorMessages.length - 1
      }
      return { ...acc, [err]: idx }
    }, {} as INumberDictionary)
  }

  private defaultErrorMessage(validator: IValidator): string | void | null {
    switch (validator.validate) {
      case 'unique':
        return i18next.t('validators.unique')?.toString?.()
      case 'required':
        return i18next.t('validators.required')?.toString?.()
      case 'required_with':
      case 'required_with_all':
      case 'required_without':
      case 'required_without_all':
        return i18next
          .t(`validators.${validator.validate}`, {
            fields: this.recipe.getFieldLabels(validator.fields).join(', ')
          })
          ?.toString?.()
      case 'required_with_values':
      case 'required_with_all_values':
      case 'required_without_values':
      case 'required_without_all_values':
        const labels = this.recipe.getFieldLabels(Object.keys(validator.fieldValues))
        return i18next
          .t(`validators.${validator.validate}`, {
            fields: Object.values(validator.fieldValues)
              .map(
                (value, index) =>
                  `"${labels[index]}"${
                    Array.isArray(value)
                      ? ` any of [${value.map((v) => `"${v}"`).join(', ')}]`
                      : ` = "${value}"`
                  }`
              )
              .join(', ')
          })
          ?.toString?.()
    }
  }

  /**
   * Removes all instances of row index from uniques array
   * and re-validates other uniques rows afterwards
   *
   * @param rowIndex
   */
  private removeUniqueIndex(rowIndex: number): void {
    this.uniques.map((_key, index) => {
      if (!isEmpty(Object.keys(_key)[0])) {
        Object.entries(this.uniques[index])
          .filter(([, v]) => v.includes(rowIndex))
          .map(([k, v]) => {
            this.uniques[index][k] = v.filter((item) => item !== rowIndex)
            this.uniques[index][k].map((i) => {
              this.rowsPending.push(i)
            })
          })
      }
    })
  }
}

export interface IValidatedRow extends Array<IValidatedCell> {}

export interface IValidatedCell {
  value: Nullable<IPrimitive>
  col: number
  info?: string[]
  warnings?: string[]
  errors: string[]
}

export function recordIsEmpty([record]: RecordTuple): boolean {
  return record.every(([value]) => isEmpty(value))
}
