/**
 * Get the average of numbers in any array
 * @param arr
 */
import uniq from 'lodash/uniq'
import sanitizeHtml from 'sanitize-html-react'

import { IDictionary, IPrimitive, Nullable } from '../types/general.interface'

export function average(arr: number[]): number {
  const sum = arr.reduce((acc, curr) => acc + curr, 0)
  return sum / arr.length
}

/**
 * Return the number of bytes in a string
 * @param str
 */
export function byteLength(str: string) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) {
      s++
    } else if (code > 0x7ff && code <= 0xffff) {
      s += 2
    }
    if (code >= 0xdc00 && code <= 0xdfff) {
      i--
    } // trail surrogate
  }
  return s
}

/**
 * Return a list of file types allowed depending on enabled features
 */
export function acceptedFileTypes(isManaged: boolean) {
  return isManaged ? '.csv,.tsv,.xls,.xlsx,.xml,.txt' : '.csv,.tsv,.txt'
}

/**
 * Convert seconds to human readable time.
 * eg. 1h 34m 16s
 * @param secs
 */
export function prettyTime(secs: number) {
  if (secs > 60 * 60) {
    const hours = Math.floor(secs / (60 * 60))
    const mins = Math.floor((secs % (60 * 60)) / 60)
    const rest = secs % 60
    return `${hours}h ${mins}m ${rest}s`
  } else if (secs > 60) {
    const mins = Math.floor(secs / 60)
    const rest = secs - mins * 60
    return `${mins}m ${rest}s`
  } else {
    return `${secs}s`
  }
}

export function isEmpty(value: Nullable<IPrimitive>): boolean {
  return value === '' || value === undefined || value === null
}

// tslint:disable-next-line:no-any
export function stringifyDictionary<T extends IDictionary<any>>(dict: T): T {
  const out: IDictionary<string> = {}
  for (const key in dict) {
    if (dict.hasOwnProperty(key)) {
      out[key] = dict[key]?.toString?.() as string
    }
  }
  return out as T
}

// tslint:disable-next-line:no-any
export function drillSet<T extends any, V extends T>(
  obj: T[][],
  row: number,
  col: number,
  value: V
): V {
  if (!obj[row]) {
    obj[row] = []
  }
  obj[row][col] = value
  return value
}

// tslint:disable-next-line:no-any
export function drillMergeArray<T extends any[], V extends T>(
  obj: T[][],
  row: number,
  col: number,
  value: V
): T {
  if (!value.length) {
    return obj[row]?.[col] || ([] as unknown as T)
  }
  if (!obj[row]) {
    obj[row] = []
  }
  if (!obj[row][col]) {
    obj[row][col] = [] as unknown as T
  }
  obj[row][col] = uniq([...obj[row][col], ...value]) as T
  return obj[row][col]
}

export function storageAvailable(type: string | number) {
  let storage
  try {
    // @ts-ignore
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

export const isRawHTML = (str: string) => {
  const a = document.createElement('div')
  a.innerHTML = str

  return a.childNodes.length && Array.from(a.childNodes).some(({ nodeType }) => nodeType === 1)
}

export const sanitizeRawHtml = (
  str: string,
  allowedTags = ['a', 'br', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'p'],
  final = false
) => {
  return sanitizeHtml(str, {
    allowedTags,
    allowedAttributes: {
      a: ['style', 'href', 'download'].concat(final ? ['target'] : []),
      div: ['style'],
      h1: ['style'],
      h2: ['style'],
      h3: ['style'],
      h4: ['style'],
      h5: ['style'],
      h6: ['style'],
      img: ['style', 'src'],
      p: ['style']
    },
    transformTags: {
      a: (_: string, attr: object) => ({
        tagName: 'a',
        attribs: {
          ...attr,
          target: '_blank'
        }
      })
    }
  })
}
